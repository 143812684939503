import React, {  useCallback, useRef, useState } from "react";
import LoadingText from "./LoadingText";


const QandAArea = () => {
    const questionRef = useRef(null);
    const [answerError, setAnswerError] = useState("");
    const [answerLoading, setAnswerLoading] = useState(false);
    const [answer, setAnswer] = useState("");
    const [url, seturl] = useState("");
    const [answerDone, setAnswerDone] = useState(false);
  
  
    const handleSearch = useCallback(async () => {
      if (answerLoading) {
        return;
      }
  
      const question = (questionRef.current).value ?? "";
      setAnswer("");
      seturl("");
      setAnswerDone(false);
  
      if (!question) {
        setAnswerError("Please ask a question.");
        return;
      }
      
  
      setAnswerLoading(true);
      setAnswerError("");
  
  
      try {
        
          const res = await fetch(`${window.env.API_SERVER}/answer`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                question,
              }),
            });
  
        if (res.status === 200) {
          console.log("200");
  
          setAnswerDone(true);
          const reader = res.body.getReader();
  
      while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setAnswerDone(true);
            break;
          }
  
          let [answer, url] = getAnswerUrl(new TextDecoder().decode(value));
       
  
          console.log(answer, url);
  
          setAnswer((prev) => prev +answer);
          console.log(answer);

          answer=="I don't know." ? seturl(" ") :seturl(url);
          console.log(url)
        }
    
      }
  
  
  
          
         else {
          setAnswerError("Sorry, something went wrong!");
        }
      } catch (err) {
        setAnswerError("Sorry, something went wrong!");
      }
  
  
      
  
      setAnswerLoading(false);
    }, [answerLoading]);
  
    const handleEnterInSearchBar = useCallback(
      async (event) => {
        if ((event).key === "Enter") {
          await handleSearch();
        }
      },
      [handleSearch]
    );
  
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
  
  
          <div className="space-y-4 text-gray-800 w-full">
        <div className="mt-2">
        </div>
        <div className="space-y-2">
          <input
            className="border rounded border-gray-200 w-full py-1 px-2"
            placeholder="e.g. What were the key takeaways from the Q1 planning meeting?"
            name="search"
            ref={questionRef}
            onKeyDown={handleEnterInSearchBar}
          />
          <div
            className="rounded-md bg-gray-50 py-1 px-4 w-max text-gray-500 hover:bg-gray-100 border border-gray-100 shadow cursor-pointer mx-auto"
            onClick={handleSearch}
          >
            {answerLoading ? (
              <LoadingText text="Answering question..." />
            ) : (
              "Ask question"
            )}
          </div>
  
        </div>
          
          </div>
          <div className="mt-4">
              {answerError && (
  
                  <div className="text-red-500">{answerError}</div>
              )}
              {answerDone && (
                  <div className="text-green-500">Done!</div>
              )}
              {answer && (
                  <div>
                    <div className="text-gray-800">{answer}</div>
                    <div><a href={url}> {url}</a></div>
                  </div>
              )}
          </div>
      </div>
  
  
      );
  
  }
  
  function getAnswerUrl(apiResponse) {
      // Parse the JSON response
      let response = JSON.parse(apiResponse);
  
      // Access the 'answerText' and 'url' from the parsed response
      let answer = response.answer.answerText;
      let url = response.answer.url;
  
      // Return the answer and url
      return [answer, url];
  }


export default QandAArea