import React from 'react'

const LoadingText = (props) => {
    return (
        <div className="text-gray-500 text-md flex flex-row justify-center items-center">
          {props.text && <div className="flex">{props.text}</div>}
        </div>
      );
}

export default LoadingText