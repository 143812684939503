import './App.css'
import QandAarea from './components/QandAArea'
function App() {



  return (
    <>

    <h3 className='red'>Beta version</h3>
      <h1 className='balck'>Ask AI about AAOIFI standards</h1>
      <QandAarea />


   
    



      
    </>
  )
}

export default App
